<template>
  <div class="d-flex min-vh-100 align-items-center justify-content-center">
    <form @submit.prevent="submit" class="py-4 px-3 border shadow-sm rounded">
     <div class="text-center mb-4">
<!--        <img class="img-fluid" src="/images/logo.svg" alt="">-->
      </div>
      <b-form-group label="User Email">
        <b-input :state="$v.email_user_name.$error ? false: null" v-model="$v.email_user_name.$model" type="text"
                 size="sm" placeholder="User Email"/>
        <template #invalid-feedback>
          <ErrorMessages :field="$v.email_user_name" name="user name"/>
        </template>
      </b-form-group>
      <b-form-group label="Password">
        <b-input :state="$v.password.$error ? false: null" v-model="$v.password.$model" type="password" size="sm"
                 placeholder="******"/>
        <template #invalid-feedback>
          <ErrorMessages :field="$v.password" name="password"/>
        </template>
      </b-form-group>

      <b-button @click="submit" size="sm" ref="formSubmit" block  value="Login" class=" mt-5 btn btn-info text-uppercase">Login</b-button>
    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import ErrorMessages from "../components/ErrorMessages";
import {loginValidation} from "../validations/loginValidation";
import {mapActions} from "vuex";
import apiService from "../service/apiService";
import {authHeader} from "../helpers/authHeader";
import Swal from "sweetalert2";
import axios from "axios";
import URL from "@/constants/baseurl";

export default {
  name: "Login",
  components: {ErrorMessages},
  mixins: [validationMixin],
  validations: loginValidation,
  data() {
    return {
        twof:'',
         email_user_name: '',
         password: '',
         button_text:'Authenticate with WSO2'
    }
  },
 async created(){
    await this.processLogoutManual()
  },
  methods: {
    ...mapActions(['login','processLogoutManual']),

    async submit() {
          this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const credentials = {
        email_user_name: this.email_user_name,
        password: this.password,
          twof:this.twof
      }
      try{
          let twof = localStorage.getItem('twof');
          // remove below line to implememt
          twof=true
          if(twof){
              credentials.twof=twof
              this.twof=twof
              await this.login(credentials)
              apiService.defaults.headers = authHeader()
              await this.$router.push({name: 'pos'})
          }
          else {
              try {
                  const res = await axios.post(`${URL.BASEURL}/api/two_factor_authentication`,{user_email:this.email_user_name});

                  if (res) {

                      try {
                          const { value: twofac } = await Swal.fire({
                              title: ` Enter One Time Code`,
                              html: `
      <div class="row">
        <input id="swal-input-2f" class="swal2-input col-md-10" placeholder="Please Enter One Time Code" value="">
      </div>
    `,
                              focusConfirm: false,
                              showCancelButton: true,
                              confirmButtonText: 'Verify',
                              cancelButtonText: 'Cancel',
                              preConfirm: () => {
                                  const twofac = Swal.getPopup().querySelector('#swal-input-2f').value;

                                  if (!twofac) {
                                      Swal.showValidationMessage('Code is Required!');
                                  }
                                  return twofac;
                              },
                          });

                          if (twofac) {
                              if(res.data.two_factor_code===twofac){
                                  localStorage.setItem("twof", twofac);
                                 await Swal.fire('success', 'Thanks for Confirmation', 'success');
                                  this.twof=twofac
                                  await this.login({
                                      email_user_name: this.email_user_name,
                                      password: this.password,
                                      twof:twofac
                                  })
                                  apiService.defaults.headers = authHeader()
                                  await this.$router.push({name: 'pos'})


                              }
                              else {
                                  Swal.fire('Error', 'Code is Invalid, Try Again', 'info');
                              }
                              // Handle the code after the user clicks "Verify" here
                              //console.log(`User entered: ${twofac}`);


                          } else {
                              // Handle the code when the user cancels or closes the dialog here
                              //  console.log('User cancelled or closed the dialog');
                          }
                      } catch (error) {
                          console.error(error);
                      }

                      //  Swal.fire('success', 'Transaction  Deleted', 'success');

                  } else {
                      //  Swal.fire('info', 'Transaction Cant be Deleted', 'info');
                  }
              } catch (error) {
                  console.error(error);
                  //  Swal.fire('Error', 'Failed to add Transaction.', 'info');
              }

          }

      }catch(error){
        const makeToast = await import('../helpers/toastMessages').then(mod => mod.makeToast.bind(this))
          makeToast('Invalid credentials, Please try again', 'danger','There was a problem')
        }
    }
  }
}
</script>

<style scoped lang="scss">
form {
  width: 500px;
  background: white;
}
</style>
